
import {Component, Mixins} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipAltTipForm from "@/components/forms/TakipAltTipForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {HesapSekliLabel} from "@/enum/HesapSekli";

@Component({
  components: {FormDialog, TakipAltTipForm},
})
export default class TakipAltTipInfo extends Mixins(ObjectInputMixin) {
  hesapSekliLabel = HesapSekliLabel;

  load() {
    this.$emit('load');
  }
}
