
import {Component, Vue} from "vue-property-decorator";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import TakipAltTipAlanlarList from "@/components/lists/TakipAltTipAlanlarList.vue";
import TakipAltTipInfo from "@/components/infos/TakipAltTipInfo.vue";
import TahsilatDusmeSirasi from "@/components/TahsilatDusmeSirasi.vue";
import {TakipAltTipHesapAyarEntity} from "@/entity/TakipAltTipHesapAyarEntity";

@Component({
  components: {
    TahsilatDusmeSirasi,
    TakipAltTipAlanlarList,
    TakipAltTipInfo,
  },
})
export default class TakipAltTipView extends Vue {
  item: TakipAltTipiEntity = new TakipAltTipiEntity();

  mounted() {
    this.load();
  }

  load() {
    this.$http.get(`/api/v1/takip-alt-tipi/${this.$route.params.id}`).then((response) => {
      this.item = response.data;
      this.item.takip_alt_tip_hesap_ayar.sort((prev: TakipAltTipHesapAyarEntity, current: TakipAltTipHesapAyarEntity) => (prev.gosterim_sirasi - current.gosterim_sirasi));
    });
  }
}
